import React from 'react';
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4 m5wa5ho9pqr-editor_css' },
  page: { className: 'home-page banner4-page m5wa56oyjy-editor_css' },
  childWrapper: {
    className: 'banner4-title-wrapper m82qo1qld2-editor_css',
    children: [
      {
        name: 'image~m5waooi7w7b',
        className: 'm82ql39vdaf-editor_css',
        children:
          'https://upload.buybuylabel.com/sfba/vockeduxmhpdlcdl/funtreelogo.svg',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>Funtree</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'banner4-title m5uw5qt5q8-editor_css',
      },
      {
        name: 'button~m7x3gygmswo',
        className: 'm7y4ndutwka-editor_css',
        children: {
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>Products List →</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
          href:
            'https://9eab8688244a494f.share.mingdao.net/public/page/67e25669fe225a0494bb06ce?%20Funtree%20SCM-Portal',
          type: 'primary',
          className: 'm7x3gzo322d-editor_css',
          target: '_blank',
        },
      },
    ],
  },
  image: {
    className: 'banner4-image m5vsu6x0exl-editor_css',
    children: 'https://static.funtree.com/funtree/banner_1.png',
  },
};
export const Feature70DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature7-wrapper m7x16t9a1qn-editor_css',
  },
  page: { className: 'home-page feature7 m7x1739zrgg-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper m7x0m3skv2t-editor_css',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <span>
              <p>联系我们</p>
            </span>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper m7x176so95e-editor_css',
    gutter: 20,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title m7x0q8816h7-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>品牌介绍</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Funtree专注于发掘和甄选深受中国消费者喜爱的食品，并供应到美国，让每一位身处异乡的华裔都能便捷地享受到安全、美味、多样的食品带来的生活趣味。
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title m7x1kdear-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>海外公司</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content m7x1a6t7s2m-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Funtree, Inc.</p>
                      <p> Irvine, CA 92618</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~m7x26ebqr28',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title m7x1kdear-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>国内公司</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content m7x1a6t7s2m-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>
                                    浙江省杭州市翠苑街道万塘路计量大厦1402室
                                  </p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~m7x1kuyjo4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title m7x1kdear-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>联系方式</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content m7x1a6t7s2m-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>电话：0755-2656266</p>
                              <p>邮箱：contact@funtree.com</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper m5vvi2v5sdd-editor_css',
  },
  page: { className: 'home-page content5 m7x0idl5whr-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper m7x0m79t0pb-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>入驻品牌</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <p>甄选入驻优质品牌，超100多款产品</p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content m7x09lc7yc7-editor_css',
          },
          img: {
            children: 'https://static.funtree.com/funtree/粮全其美.png',
            className: 'm5w1sjj11g7-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        <b>粮全其美</b>
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content m5w3avojchb-editor_css',
            target: '',
            href: '',
          },
          img: {
            children: 'https://static.funtree.com/funtree/盒马.png',
            className: 'm5w3c2ggy7g-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>
                    <b>盒马</b>
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://static.funtree.com/funtree/大董.png',
            className: 'm5w3gykbd4-editor_css',
          },
          content: {
            children: (
              <span>
                <p>大董</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://static.funtree.com/funtree/风再来.png',
            className: 'm5w3ocmyvze-editor_css',
          },
          content: {
            children: (
              <span>
                <p>凤再来</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://static.funtree.com/funtree/早约.png',
            className: 'm5w3wc1p06-editor_css',
          },
          content: {
            children: (
              <span>
                <p>枣悦</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://static.funtree.com/funtree/zengli.png',
            className: 'm5w3xg898ge-editor_css',
          },
          content: {
            children: (
              <span>
                <p>甑礼</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m5vvjnv25o',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://static.funtree.com/funtree/造物之.png',
            className: 'm5w3hnpme3-editor_css',
          },
          content: {
            children: (
              <span>
                <p>造物志</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m5vvjp2t6c',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://static.funtree.com/funtree/喜多方.png',
            className: 'm5w3y2s8uj7-editor_css',
          },
          content: {
            children: (
              <span>
                <p>喜多方</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Teams40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content8-wrapper m7x3tm7tnsu-editor_css',
  },
  page: { className: 'home-page content8 m7x422juuju-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper m7x46b7facf-editor_css',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>热卖产品</p>
            </span>
          </span>
        ),
        className: 'title-h1 m7x40izaw1m-editor_css',
      },
    ],
  },
  block: {
    className: 'content-wrapper',
    children: [
      {
        name: 'block0',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://static.funtree.com/funtree/guihua.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>桂花酒酿大幅</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>盒马 | 小吃</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://static.funtree.com/funtree/mati.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <span>
                  <p>冰糖脆马蹄</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>盒马 | 小吃</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://static.funtree.com/funtree/chayedan.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>茶叶蛋炖煮包</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <p>盒马 | 调味料</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://static.funtree.com/funtree/shanzha.jpg',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>蔓越莓山楂条</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <p>盒马 | 小吃</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://static.funtree.com/funtree/paojiao.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>泡椒脆脆笋尖</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <p>盒马 | 小吃</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://static.funtree.com/funtree/suancaiyu.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>酸菜鱼</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>太二 | 预制菜</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://static.funtree.com/funtree/huangnuoyumi.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>黄糯玉米</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>粮全其美 | 小吃</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://static.funtree.com/funtree/ynagzhiganlu.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>杨枝甘露</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>粮全其美 | 饮料</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: {
    className: 'home-page-wrapper pricing0-wrapper m7x5rb32xsl-editor_css',
  },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children: 'https://static.funtree.com/funtree/welcome.png',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper m7x68brs7e-editor_css',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>👏🏻 Funtree 期待与您合作</p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      无论您是获得市场认可的品牌商家还是具备竞争力的渠道平台，或者在美国具有线下渠道的销售，欢迎联系我们。
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-content m7x4ld8pkpg-editor_css',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              <p>已上线100+款产品</p>
            </span>
          </span>
        ),
        className: 'pricing0-pricing m7x52gkmi6n-editor_css',
      },
      {
        name: 'button',
        children: {
          href:
            'https://9eab8688244a494f.share.mingdao.net/public/page/67e25669fe225a0494bb06ce?%20Funtree%20SCM-Portal',
          type: 'primary',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>Products List →</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
          target: '_blank',
          className: 'm7x4eympi5e-editor_css',
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>©2025 Funtree, Inc.&nbsp;All Rights Reserved</span>
        </span>
      </span>
    ),
  },
};
